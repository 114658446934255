/* Tailwind core */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* CSS variables */
@layer base {
  /* Prevents zooming in (mobile) */
  input,
  textarea,
  select {
    font-size: 16px;
  }

  .safe-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  html[data-theme="prime"] .prime-only {
    display: flex;
  }

  html:not([data-theme="prime"]) .prime-only {
    display: none;
  }

  html:not([data-theme="prime"]) .not-prime {
    display: flex;
  }

  html[data-theme="prime"] .not-prime {
    display: none;
  }

  html[data-theme="prime"] {
    /*
     * PRIMITIVES
     */

    /* Gray */
    --gray-900: 215 23% 15%;
    --gray-800: 240 6% 31%;
    --gray-600: 211 9% 45%;
    --gray-100: 300 14% 99%;
    --gray-0: 0 0% 100%;

    /* Brand */
    --primary: 238 82% 64%;
    --secondary: 230 27% 40%;

    /* Accents */
    --positive: 166 79% 42%;

    /*
     * END PRIMITIVES (BELOW ARE DEPRECATED)
     */

    /* Brand */
    --brand: 237 82% 64%;

    /* Values */
    --radius: 0.5rem;

    /* Shadcn/ui */
    /* https://ui.shadcn.com/docs/installation/manual */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --color-3: #ee1d2e;
    --color-6: #3bc884;
    --color-16: #f3f3f3;
    --color-22: hsl(0, 0%, 95%);
  }

  html[data-theme="zelis"] {
    /*
     * PRIMITIVES
     */

    /* Gray */
    --gray-900: 215 23% 15%;
    --gray-800: 240 6% 31%;
    --gray-600: 211 9% 45%;
    --gray-100: 300 14% 99%;
    --gray-0: 0 0% 100%;

    /* Brand */
    --primary: 244 94% 53%;
    --secondary: 230 27% 40%;

    /* Accents */
    --positive: 166 79% 42%;

    /*
     * END PRIMITIVES (BELOW ARE DEPRECATED)
     */

    /* Brand */
    --brand: 237 82% 64%;

    /* Values */
    --radius: 0.5rem;

    /* Shadcn/ui */
    /* https://ui.shadcn.com/docs/installation/manual */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    /* Zelis: Simplifying styles and list of colors to just increment by one when encountering a new one */

    --color-1: #4f257f;
    --color-2: #727edd;
    --color-3: #ee1d2e;
    --color-4: #ffb32c;
    --color-5: #2516f8;
    --color-6: #3bc884;
    --color-7: #23004b;
    --color-8: #f7f6ff;

    --color-9: #320fff;
    --color-10: #e4dff7;
    --color-11: #3e1d7e1a;
    --color-12: #392182;
    --color-13: #b0a2ca;
    --color-14: #331868;
    --color-15: #3318681a;
    --color-16: #ffffff;
    --color-17: #ada6b8;
    --color-18: #dee1e8;
    --color-19: #f4f4f6;
    --color-20: #3e1d7e;
    --color-21: #b0a2ca1a;
    --color-22: #1e1b4b;
  }

  html[data-theme="prime-native"] {
    --logo: url("/assets/prime/logo.svg");
    --navigation-logo: url("/assets/prime/logo.svg");

    --border: #e2e8f0;

    --sidebar-surface: #2b535e;
    --sidebar-highlight-primary: #bbffee;
    --sidebar-text-primary: #ffffff;
    --sidebar-highlight-inverted: #2b535e;
    --sidebar-highlight-secondary: #bbffee;

    --surface-light: #fafcfc;
    --surface-card: #ffffff;
    --surface-medium: #efeeea;
    --surface-dark: #e4e2dd;

    --tint-dim-soft: #00000008;
    --tint-dim-medium: #00000012;
    --tint-light-medium: #ffffff12;
    --tint-light-soft: #ffffff08;
    --tint-light-heavy: #ffffff1a;
    --tint-dim-heavy: #00000033;

    --accent-affirmative: #17bf98;
    --accent-negative: #f44a55;

    --text-secondary: #2e464d;
    --text-tertiary: #526266;
    --text-dimmed: #1d242e4d;
    --text-branded: #2b535e;

    --button-primary: #2b535e;
    --button-primary-inverted: #ffffff;

    --corner-pill: 0.375rem;
    --corner-pill-animated: 2rem;
    --corner-containers: 0.5rem;
    --corner-button: 0.5rem;

    /* Promo Term Item */
    --promo-item-color-1: #4f257f;
    --promo-item-color-2: #727edd;
    --promo-item-color-3: #ee1d2e;
    --promo-item-color-4: #ffb32c;
    --promo-item-color-5: #3bc884;
  }

  html[data-theme="prime"] {
    --logo: url("/assets/pop/logo.svg");
    --navigation-logo: url("/assets/pop/logo.svg");

    --border: #e2e8f0;

    --sidebar-surface: #595fef;
    --sidebar-highlight-primary: #49ffd5;
    --sidebar-text-primary: #ffffff;
    --sidebar-highlight-inverted: #595fef;
    --sidebar-highlight-secondary: #eedf5a;

    --surface-light: #fafcfc;
    --surface-card: #ffffff;
    --surface-medium: #f3f3f3;
    --surface-dark: #f3f3f3;

    --tint-dim-soft: #00000008;
    --tint-dim-medium: #00000012;
    --tint-light-medium: #ffffff12;
    --tint-light-soft: #ffffff08;
    --tint-light-heavy: #ffffff1a;
    --tint-dim-heavy: #00000033;

    --accent-affirmative: #17bf98;
    --accent-negative: #f44a55;

    --text-secondary: #4b4b55;
    --text-tertiary: #69737e;
    --text-dimmed: #1d242e4d;
    --text-branded: #595fef;

    --button-primary: #595fef;
    --button-primary-inverted: #ffffff;

    --corner-pill: 0.75rem;
    --corner-pill-animated: 2rem;
    --corner-containers: 1rem;
    --corner-button: 0.75rem;

    /* Promo Term Item */
    --promo-item-color-1: #4f257f;
    --promo-item-color-2: #727edd;
    --promo-item-color-3: #ee1d2e;
    --promo-item-color-4: #ffb32c;
    --promo-item-color-5: #3bc884;
  }

  html[data-theme="zelis"] {
    --logo: url("/assets/zelis/logo.svg");
    --navigation-logo: url("/assets/zelis/logo.svg");

    --border: #e2e8f0;

    /* embed assets */
    --embed-bg-default: url("/assets/zelis/embed-inline-background-default.svg");
    --embed-bg-start: url("/assets/zelis/embed-inline-background-start.svg");

    --sidebar-surface: #23004b;
    --sidebar-highlight-primary: #320fff;
    --sidebar-text-primary: #ffffff;
    --sidebar-highlight-inverted: #f7f6ff;
    --sidebar-highlight-secondary: #ffb33b;

    --surface-light: #fafcfc;
    --surface-card: #ffffff;
    --surface-medium: #fafcfc;
    --surface-dark: #fafcfc;

    --tint-dim-soft: #00000008;
    --tint-dim-medium: #00000012;
    --tint-light-medium: #ffffff12;
    --tint-light-soft: #ffffff08;
    --tint-light-heavy: #ffffff1a;
    --tint-dim-heavy: #00000033;

    --accent-affirmative: #17bf98;
    --accent-negative: #f44a55;

    --text-secondary: #4b4b55;
    --text-tertiary: #69737e;
    --text-dimmed: #1d242e4d;
    --text-branded: #23004b;

    --button-primary: #320fff;
    --button-primary-inverted: #ffffff;

    --corner-pill: 0.188rem;
    --corner-pill-animated: 0.188rem;
    --corner-containers: 0.375rem;
    --corner-button: 0.25rem;

    /* Promo Term Item */
    --promo-item-color-1: #4f257f;
    --promo-item-color-2: #727edd;
    --promo-item-color-3: #ee1d2e;
    --promo-item-color-4: #ffb32c;
    --promo-item-color-5: #3bc884;
  }

  html[data-theme="highbeam"] {
    --logo: url("/assets/highbeam/logo.svg");
    --navigation-logo: url("/assets/highbeam/navigation-logo.svg");

    --border: #e2e8f0;

    --sidebar-surface: #281c6a;
    --sidebar-highlight-primary: #714ffe;
    --sidebar-text-primary: #f6f5ff;
    --sidebar-highlight-inverted: #f7f6ff;
    --sidebar-highlight-secondary: #c1b2ff;

    --surface-light: #fafaff;
    --surface-card: #ffffff;
    --surface-medium: #eeebff;
    --surface-dark: #dcd8f7;

    --tint-dim-soft: #00000008;
    --tint-dim-medium: #00000012;
    --tint-light-medium: #ffffff12;
    --tint-light-soft: #ffffff08;
    --tint-light-heavy: #ffffff1a;
    --tint-dim-heavy: #00000033;

    --accent-affirmative: #17bf98;
    --accent-negative: #f44a55;

    --text-secondary: #312f3e;
    --text-tertiary: #464450;
    --text-dimmed: #1d242e4d;
    --text-branded: #3b20ca;

    --button-primary: #3b20ca;
    --button-primary-inverted: #eeebff;

    --corner-pill: 0.188rem;
    --corner-pill-animated: 0.188rem;
    --corner-containers: 0.375rem;
    --corner-button: 0.25rem;

    /* Promo Term Item */
    --promo-item-color-1: #7857fe;
    --promo-item-color-2: #7857fe;
    --promo-item-color-3: #7857fe;
    --promo-item-color-4: #7857fe;
    --promo-item-color-5: #7857fe;
  }

  .partner-logo {
    aspect-ratio: 68 / 13;
    background-image: var(--logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .navigation-logo {
    aspect-ratio: 68 / 13;
    background-image: var(--navigation-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .embed-background-default {
    background-image: var(--embed-bg-default);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .embed-background-start {
    background-image: var(--embed-bg-start);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background font-normal text-foreground;
  }
}
